import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { FileUploadStatus } from 'src/app/shared/model/file-upload.status';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ExportAsExcelFileService } from 'src/app/shared/service/exportAsExcelFile.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AdduserComponent } from '../modals/adduser/adduser.component';
import { DeleteuserComponent } from '../modals/deleteuser/deleteuser.component';
import { InfouserComponent } from '../modals/infouser/infouser.component';

@Component({
  selector: 'app-accessmanagement',
  templateUrl: './accessmanagement.component.html',
  styleUrls: ['./accessmanagement.component.css']
})
export class AccessmanagementComponent implements OnInit, AfterViewInit, OnDestroy {
  public users!: User[];
  public user!: User;
  public refreshing!: boolean;
  public selectedUser!: User;
  public fileName!: string;
  public profileImage!: File;
  private subscriptions: Subscription[] = [];
  public editUser = new User();
  private currentUsername!: string;
  public fileStatus = new FileUploadStatus();

  displayedColumns: string[] = ['utilisateur', 'roles', 'manager', 'phone', 'numberDaysLeave', 'actions'];
  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private router: Router, private authenticationService: AuthenticationService, private exportAsExcelFileService: ExportAsExcelFileService,
    private userService: UserService, private notificationService: NotificationService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUserFromLocalCache();
    this.getUsers(true);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public getUsers(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.userService.getUsers().subscribe(
        (response: User[]) => {
          this.userService.addUsersToLocalCache(response);
          this.users = [];
          for (let i = 0; i < response.length; i++) {
            if (response[i]['role'] != Role.ADMIN) {
              let userObj = {} as User;
              userObj = response[i];
              if (response[i]['manager'] != null) {
                userObj['managerUsername'] = response[i]['manager']['username'];
              }
              this.users.push(userObj);
            }
          }
          const users = this.users.map((obj) => {
            return { ...obj, date: new Date(obj.joinDate) };
          });
          this.dataSource.data = users.sort(
            (objA, objB) => objB.date.getTime() - objA.date.getTime(),
          );
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${this.users.length} user(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public onSelectUser(selectedUser: User): void {
    const modalRef = this.modalService.open(InfouserComponent);
    modalRef.componentInstance.selectedUser = selectedUser;
  }

  public createNewUser(): void {
    const modalRef = this.modalService.open(AdduserComponent, { centered: true });
    modalRef.result.then(
      () => {
        this.getUsers(false);
      },
      () => {
      });
  }

  public onAddNewUser(): void {
  }

  public onDeleteUser(index: number): void {
    const modalRef = this.modalService.open(DeleteuserComponent);
    modalRef.componentInstance.username = this.dataSource.data[index]['username'];
    modalRef.result.then(
      () => {
        this.getUsers(false);
      },
      () => {
      });
  }

  public onEditUser(index: number): void {
    this.router.navigateByUrl('/controlpanel/edituser/' + this.dataSource.data[index]['username']);
  }

  public downloadAllUsers(): void {
    for (let i = 0; i < this.users.length; i++) {
      delete this.users[i].userId;
      delete this.users[i].profileImageUrl;
      delete this.users[i].lastLoginDate;
      delete this.users[i].lastLoginDateDisplay;
      delete this.users[i].authorities;
      delete this.users[i].dateDeactivation;
      delete this.users[i].active;
      delete this.users[i].notLocked;
      delete this.users[i].manager;
    }
    this.exportAsExcelFileService.exportAsExcelFile(this.users);
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
