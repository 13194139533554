import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { HolidayType } from 'src/app/shared/model/holidaytype';
import { Leave } from 'src/app/shared/model/leave';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import * as moment from 'moment';
import { CalendarEvent } from 'angular-calendar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LeaveStatus } from 'src/app/shared/enum/leave_status.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { ExportAsExcelFileService } from 'src/app/shared/service/exportAsExcelFile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SickDocumentviewerComponent } from '../modals/sick-documentviewer/sick-documentviewer.component';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit, OnDestroy {
  public refreshing!: boolean;
  public user!: User;
  public userFromLocalCache!: User;
  private subscriptions: Subscription[] = [];
  public events: Array<CalendarEvent> = [];
  public holidayTypes: HolidayType[];
  public totalNumberOfDays = 0;
  public totalNumberOfDaysMax = 0;
  public totalNumberOfDaysMin = 0;
  public dateMin: string;
  public form!: FormGroup;
  public leaves: Leave[];
  public leavesByUser: Leave[];
  public submitted = false;
  public sickDocument!: File;
  public fileName: string;
  public files: any;
  public pdfContent: any;
  public isSick = false;

  displayedColumns: string[] = ['leaveType', 'user', 'date', 'numberDays', 'lineManager', 'middleManager', 'admin'];
  public dataSource = new MatTableDataSource<any>();

  displayedColumnsUser: string[] = ['leaveType', 'date', 'numberDays', 'lineManager', 'middleManager', 'admin'];
  public dataSourceUser = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('pdfview') pdfview!: ElementRef;

  constructor(private leaveService: LeaveService, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private holidayService: HolidayService,
    private notificationService: NotificationService, public datepipe: DatePipe, private exportAsExcelFileService: ExportAsExcelFileService, private modalService: NgbModal, private userService: UserService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      leaveType: ['', Validators.required],
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required],
      message: ['']
    });
    this.userFromLocalCache = this.authenticationService.getUserFromLocalCache();
    this.getUser(this.authenticationService.getUserFromLocalCache().username);
    this.getHolidayTypes(false);
    this.getCalendarEvents(false);
    this.getAllLeaves(false);
    this.getLeavesByUser(false);
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceUser.paginator = this.paginator;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public applyFilterUser(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceUser.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceUser.paginator) {
      this.dataSourceUser.paginator.firstPage();
    }
  }

  public getUser(username: string): void {
    this.subscriptions.push(
      this.userService.getUserByUserName(username).subscribe(
        (response: User) => {
          this.user = response;
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public getAllLeaves(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.leaveService.getAllLeaves().subscribe(
        (response: Leave[]) => {
          this.leaves = [];
          for (let i = 0; i < response.length; i++) {
            let leaveObj = {} as Leave;
            leaveObj['id'] = response[i]['id'];
            leaveObj['createdAt'] = response[i]['createdAt'];
            leaveObj['leaveType'] = response[i]['leaveType'];
            leaveObj['dateFrom'] = response[i]['dateFrom'];
            leaveObj['dateTo'] = response[i]['dateTo'];
            leaveObj['numberDays'] = response[i]['numberDays'];
            leaveObj['user'] = response[i]['user']['firstName'] + " " + response[i]['user']['lastName'];
            leaveObj['reviewedBy'] = response[i]['reviewedBy'];
            leaveObj['status'] = response[i]['status'];
            leaveObj['leaveDetailsId'] = response[i]['leaveDetailsId'];
            leaveObj['sickDocumentUrl'] = response[i]['sickDocumentUrl'];
            if (response[i]['user']['role'] == Role.USER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['lineManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_LINE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_LINE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-times text-danger";
                  leaveObj['middleManager'] = "fa fa-times text-danger";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-times text-danger";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.LINE_MANAGER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                  leaveObj['middleManager'] = "fa fa-times text-danger";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveObj['middleManager'] = "fa fa-times text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveObj['middleManager'] = "fa fa-times text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['middleManager'] = "fa fa-times text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.MIDDLE_MANAGER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.TOP_MANAGER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.HR_MANAGER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            }
            this.leaves.push(leaveObj);
          }
          const allLeaves = this.leaves.map((obj) => {
            return { ...obj, date: new Date(obj.createdAt) };
          });
          this.dataSource.data = allLeaves.sort(
            (objA, objB) => objB.date.getTime() - objA.date.getTime(),
          );
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} leave(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  public getLeavesByUser(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.leaveService.getLeavesByUser(this.userFromLocalCache["username"]).subscribe(
        (response: Leave[]) => {
          this.leavesByUser = [];
          for (let i = 0; i < response.length; i++) {
            let leaveByUserObj = {} as Leave;
            leaveByUserObj['id'] = response[i]['id'];
            leaveByUserObj['createdAt'] = response[i]['createdAt'];
            leaveByUserObj['leaveType'] = response[i]['leaveType'];
            leaveByUserObj['dateFrom'] = response[i]['dateFrom'];
            leaveByUserObj['dateTo'] = response[i]['dateTo'];
            leaveByUserObj['numberDays'] = response[i]['numberDays'];
            leaveByUserObj['reviewedBy'] = response[i]['reviewedBy'];
            leaveByUserObj['status'] = response[i]['status'];
            leaveByUserObj['leaveDetailsId'] = response[i]['leaveDetailsId'];
            leaveByUserObj['sickDocumentUrl'] = response[i]['sickDocumentUrl'];
            if (response[i]['user']['role'] == Role.USER) {
              switch (leaveByUserObj['status']) {
                case LeaveStatus.PENDING:
                  leaveByUserObj['lineManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveByUserObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_LINE_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_LINE_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-times text-danger";
                  leaveByUserObj['middleManager'] = "fa fa-times text-danger";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-times text-danger";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveByUserObj['lineManager'] = "fa fa-check text-success";
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.LINE_MANAGER) {
              switch (leaveByUserObj['status']) {
                case LeaveStatus.PENDING:
                  leaveByUserObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveByUserObj['middleManager'] = "fa fa-check text-success";
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                  leaveByUserObj['middleManager'] = "fa fa-times text-danger";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveByUserObj['middleManager'] = "fa fa-times text-success";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveByUserObj['middleManager'] = "fa fa-times text-success";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveByUserObj['middleManager'] = "fa fa-times text-success";
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.MIDDLE_MANAGER) {
              switch (leaveByUserObj['status']) {
                case LeaveStatus.PENDING:
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.TOP_MANAGER) {
              switch (leaveByUserObj['status']) {
                case LeaveStatus.PENDING:
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.HR_MANAGER) {
              switch (leaveByUserObj['status']) {
                case LeaveStatus.PENDING:
                  leaveByUserObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveByUserObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveByUserObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            }
            this.leavesByUser.push(leaveByUserObj);
          }
          const leavesByUser = this.leavesByUser.map((obj) => {
            return { ...obj, date: new Date(obj.createdAt) };
          });
          this.dataSourceUser.data = leavesByUser.sort(
            (objA, objB) => objB.date.getTime() - objA.date.getTime(),
          );
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} leave(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  public downloadLeavesByUser(): void {
    for (let i = 0; i < this.leavesByUser.length; i++) {
      delete this.leavesByUser[i].id;
      delete this.leavesByUser[i].lineManager;
      delete this.leavesByUser[i].middleManager;
      delete this.leavesByUser[i].admin;
      delete this.leavesByUser[i].leaveDetailsId;
      delete this.leavesByUser[i].sickDocumentUrl;
    }
    this.exportAsExcelFileService.exportAsExcelFile(this.leavesByUser);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public getHolidayTypes(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.holidayService.getHolidayTypes().subscribe(
        (response: HolidayType[]) => {
          this.holidayTypes = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} user(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const formData = this.leaveService.createLeaveDataForm(null, this.form.value, this.sickDocument);
    formData.append('dateFrom', this.datepipe.transform(this.form.value['dateFrom'], 'dd/MM/yyyy'));
    formData.append('dateTo', this.datepipe.transform(this.form.value['dateTo'], 'dd/MM/yyyy'));
    formData.append('reviewedBy', this.userFromLocalCache['manager']['username']);
    formData.append('username', this.userFromLocalCache.username);
    formData.append('numberDays', JSON.stringify(this.totalNumberOfDays));
    this.subscriptions.push(
      this.leaveService.addLeave(formData).subscribe(
        (response: Leave) => {
          this.form.reset();
          this.totalNumberOfDays = 0;
          this.totalNumberOfDaysMax = 0;
          this.totalNumberOfDaysMin = 0;
          this.fileName = null;
          this.isSick = false;
          this.submitted = false;
          this.getAllLeaves(false);
          this.getLeavesByUser(false);
          this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} sent successfully`);
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.submitted = false;
        }
      )
    );
  }

  public onSickDocumentChange(event: any): void {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.sickDocument = this.files;
  }

  public selectSick(event: any) {
    if (event.target.value == "Congé maladie") {
      this.isSick = true;
    } else {
      this.isSick = false;
    }
  }

  public showData(event: any) {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.getBase64(this.files).then(
      (data: any) => {
        this.pdfContent =
          URL.createObjectURL(this.b64toBlob(data.split("data:application/pdf;base64,")[1], 'application/pdf')) +
          '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';

        this.pdfview.nativeElement.setAttribute('data', this.pdfContent);
      }
    );
  }

  public onSelectLeave(selectedLeave: Leave): void {
    if (selectedLeave['leaveType'] == 'Congé maladie') {
      const modalRef = this.modalService.open(SickDocumentviewerComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.selectedLeave = selectedLeave;
    }
  }

  public daysBetween(from: Date, to: Date) {
    const fromDate = moment(from).startOf('day');
    const toDate = moment(to).endOf('day');

    const span = moment.duration(toDate.diff(fromDate)).asDays();
    const days = [];
    for (let i = 0; i <= span; i++) {
      days.push(moment(fromDate).add(i, 'day').startOf('day'));
    }
    return days;
  }

  public getBusinessDatesCount(startDate: Date, endDate: Date) {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      if (!((dayOfWeek == 6) || (dayOfWeek == 0))) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000
    }
    return count;
  }

  public getCalendarEvents(showNotification: boolean): void {
    this.subscriptions.push(
      this.holidayService.getCalendarEvents().subscribe(
        (response: CalendarEvent[]) => {
          this.events = [];
          for (let i = 0; i < response.length; i++) {
            let eventsObj = {} as CalendarEvent;
            eventsObj.start = new Date(response[i]['start']);
            eventsObj.end = new Date(response[i]['end']);
            eventsObj.title = response[i]['title'];
            this.events.push(eventsObj);
          }
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} calendarEvent(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    );
  }

  public getTotalNumberOfDays(event: any) {
    const dateMax = event.target.value;
    var numberDays = this.getBusinessDatesCount(new Date(this.dateMin), new Date(dateMax));
    for (let i = 0; i < this.events.length; i++) {
      const days = this.daysBetween(this.events[i]['start'], this.events[i]['end']);
      days.map(d => {
        if (new Date(d.toString()) > new Date(this.dateMin) && new Date(d.toString()) < new Date(dateMax)) {
          numberDays -= 1;
        }
      }
      )
    }
    this.totalNumberOfDays = numberDays;
    this.totalNumberOfDaysMax = numberDays;
    this.totalNumberOfDaysMin = numberDays - 0.5;
  }

  public getMinDate(event: any) {
    this.dateMin = event.target.value;
  }

  private b64toBlob(b64Data: any, contentType: any) {
    var byteCharacters = atob(b64Data);

    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
