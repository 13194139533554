import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeaveStatus } from 'src/app/shared/enum/leave_status.enum';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { Leave } from 'src/app/shared/model/leave';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ExportAsExcelFileService } from 'src/app/shared/service/exportAsExcelFile.service';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { SickDocumentviewerComponent } from '../modals/sick-documentviewer/sick-documentviewer.component';

@Component({
  selector: 'app-managerhistoryleave',
  templateUrl: './managerhistoryleave.component.html',
  styleUrls: ['./managerhistoryleave.component.css']
})
export class ManagerhistoryleaveComponent implements OnInit {
  public refreshing!: boolean;
  public leaves: Leave[];

  displayedColumns: string[] = ['leaveType', 'user', 'date', 'numberDays', 'lineManager', 'middleManager', 'admin'];
  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private leaveService: LeaveService, private notificationService: NotificationService, private authenticationService: AuthenticationService, private modalService: NgbModal, private exportAsExcelFileService: ExportAsExcelFileService) { }

  ngOnInit(): void {
    this.getLeavesByManager(false);
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public getLeavesByManager(showNotification: boolean): void {
    this.refreshing = true;
    this.leaveService.getAllLeaves().subscribe(
      (response: Leave[]) => {
        this.leaves = [];
        for (let i = 0; i < response.length; i++) {
          if (response[i]['user']['manager']['username'] == this.authenticationService.getUserFromLocalCache().username) {
            let leaveObj = {} as Leave;
            leaveObj['id'] = response[i]['id'];
            leaveObj['createdAt'] = response[i]['createdAt'];
            leaveObj['leaveType'] = response[i]['leaveType'];
            leaveObj['dateFrom'] = response[i]['dateFrom'];
            leaveObj['dateTo'] = response[i]['dateTo'];
            leaveObj['numberDays'] = response[i]['numberDays'];
            leaveObj['user'] = response[i]['user']['firstName'] + " " + response[i]['user']['lastName'];
            leaveObj['reviewedBy'] = response[i]['reviewedBy'];
            leaveObj['status'] = response[i]['status'];
            leaveObj['leaveDetailsId'] = response[i]['leaveDetailsId'];
            leaveObj['sickDocumentUrl'] = response[i]['sickDocumentUrl'];
            if (response[i]['user']['role'] == Role.USER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['lineManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_LINE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_LINE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-times text-danger";
                  leaveObj['middleManager'] = "fa fa-times text-danger";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-times text-danger";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['lineManager'] = "fa fa-check text-success";
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            } else if (response[i]['user']['role'] == Role.LINE_MANAGER) {
              switch (leaveObj['status']) {
                case LeaveStatus.PENDING:
                  leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                  break;
                case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_HR_MANAGER:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.APPROVED_BY_ADMIN:
                  leaveObj['middleManager'] = "fa fa-check text-success";
                  leaveObj['admin'] = "fa fa-check text-success";
                  break;
                case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                  leaveObj['middleManager'] = "fa fa-times text-danger";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_TOP_MANAGER:
                  leaveObj['middleManager'] = "fa fa-times text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_HR_MANAGER:
                  leaveObj['middleManager'] = "fa fa-times text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
                case LeaveStatus.DENIED_BY_ADMIN:
                  leaveObj['middleManager'] = "fa fa-times text-success";
                  leaveObj['admin'] = "fa fa-times text-danger";
                  break;
              }
            }
            this.leaves.push(leaveObj);
          }
        }
        const allLeaves = this.leaves.map((obj) => {
          return { ...obj, date: new Date(obj.createdAt) };
        });
        this.dataSource.data = allLeaves.sort(
          (objA, objB) => objB.date.getTime() - objA.date.getTime(),
        );
        this.refreshing = false;
        if (showNotification) {
          this.sendNotification(NotificationType.SUCCESS, `${response.length} leave(s) loaded successfully.`);
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        this.refreshing = false;
      }
    )
  }

  public downloadLeavesByManager(): void {
    for (let i = 0; i < this.leaves.length; i++) {
      delete this.leaves[i].id;
      delete this.leaves[i].lineManager;
      delete this.leaves[i].middleManager;
      delete this.leaves[i].admin;
      delete this.leaves[i].leaveDetailsId;
      delete this.leaves[i].sickDocumentUrl;
    }
    this.exportAsExcelFileService.exportAsExcelFile(this.leaves);
  }

  public onSelectLeave(selectedLeave: Leave): void {
    if (selectedLeave['leaveType'] == 'Congé maladie') {
      this.leaveService.getSickDocument(selectedLeave["leaveDetailsId"]).subscribe((responseMessage) => {
        let file = new Blob([responseMessage], { type: 'application/pdf' });
        if (file["size"] != 0) {
          const modalRef = this.modalService.open(SickDocumentviewerComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.selectedLeave = selectedLeave;
        }
      })
    }
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
