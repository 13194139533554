<mat-toolbar>
    <mat-toolbar-row class="justify-content-between" style="background: #b6931f;">
        <div class="row mr-2">
            <!-- <p style="color: #F3F5FF;" [hidden]="!isAdmin">Panel Administrateur</p>
            <p style="color: #F3F5FF;" [hidden]="isAdmin">Utilisateur</p> -->
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-list>
    <mat-list-item routerLink="/controlpanel/dashboard" routerLinkActive="active">
        <mat-icon>dashboard</mat-icon> Dashboard
    </mat-list-item>
    <mat-list-item routerLink="/controlpanel/accessmanagement" routerLinkActive="active" [hidden]="!isAdmin">
        <mat-icon>supervisor_account</mat-icon> Gestion des utilisateurs
    </mat-list-item>
    <!-- <mat-list-item routerLink="/controlpanel/resources" routerLinkActive="active" [hidden]="!isAdmin">
        <mat-icon>dashboard</mat-icon> Resources
    </mat-list-item> -->
    <mat-list-item routerLink="/controlpanel/politics" routerLinkActive="active" [hidden]="!isAdmin">
        <mat-icon>account_balance</mat-icon> Politiques
    </mat-list-item>
    <!-- <mat-list-item routerLink="/controlpanel/reports" routerLinkActive="active" [hidden]="!isAdminOrManager">
        <mat-icon>feedback</mat-icon> Notes de réunions
    </mat-list-item> -->
    <mat-list-item routerLink="/controlpanel/holidays" routerLinkActive="active" [hidden]="onlyAdmin">
        <mat-icon>calendar_today</mat-icon> Mes congés
    </mat-list-item>
    <mat-list-item routerLink="/controlpanel/team" routerLinkActive="active" [hidden]="!isAdminOrManager">
        <mat-icon>group_work</mat-icon> Mon équipe
    </mat-list-item>
    <mat-list-item routerLink="/controlpanel/historyleaves" routerLinkActive="active" [hidden]="!isAdmin">
        <mat-icon>history</mat-icon> Historique des congés
    </mat-list-item>
    <mat-list-item routerLink="/controlpanel/managerhistoryleaves" routerLinkActive="active" [hidden]="!onlyManager">
        <mat-icon>history</mat-icon> Historique des congés
    </mat-list-item>
    <mat-list-item routerLink="/controlpanel/administrativerequest" routerLinkActive="active">
        <mat-icon>contact_support</mat-icon> Requête administrative
    </mat-list-item>
</mat-list>