import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { startOfMonth, endOfMonth } from 'date-fns';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeaveStatus } from 'src/app/shared/enum/leave_status.enum';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { HolidayType } from 'src/app/shared/model/holidaytype';
import { Leave } from 'src/app/shared/model/leave';
import { ExportAsExcelFileService } from 'src/app/shared/service/exportAsExcelFile.service';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { DeleteleavedetailsComponent } from '../modals/deleteleavedetails/deleteleavedetails.component';
import { SickDocumentviewerComponent } from '../modals/sick-documentviewer/sick-documentviewer.component';
import * as moment from 'moment';
import { CalendarEvent } from 'angular-calendar';

@Component({
  selector: 'app-historyleave',
  templateUrl: './historyleave.component.html',
  styleUrls: ['./historyleave.component.css']
})
export class HistoryleaveComponent implements OnInit {
  public refreshing!: boolean;
  public leaves: Leave[];
  public leavesFilter: Leave[];
  public dateMin: string;
  public holidayTypes: HolidayType[];
  public events: Array<CalendarEvent> = [];
  public form!: FormGroup;

  displayedColumns: string[] = ['leaveType', 'user', 'date', 'numberDays', 'lineManager', 'middleManager', 'admin', 'actions'];
  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private leaveService: LeaveService, private notificationService: NotificationService, private holidayService: HolidayService, private formBuilder: FormBuilder,
    private modalService: NgbModal, public datepipe: DatePipe, private exportAsExcelFileService: ExportAsExcelFileService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      leaveType: ['', Validators.required],
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required]
    });
    this.getAllLeaves(false);
    this.getHolidayTypes(false);
    this.getCalendarEvents(false);
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public getAllLeaves(showNotification: boolean): void {
    this.refreshing = true;
    this.leaveService.getAllLeaves().subscribe(
      (response: Leave[]) => {
        this.leaves = [];
        for (let i = 0; i < response.length; i++) {
          let leaveObj = {} as Leave;
          leaveObj['id'] = response[i]['id'];
          leaveObj['createdAt'] = response[i]['createdAt'];
          leaveObj['leaveType'] = response[i]['leaveType'];
          leaveObj['dateFrom'] = response[i]['dateFrom'];
          leaveObj['dateTo'] = response[i]['dateTo'];
          leaveObj['numberDays'] = response[i]['numberDays'];
          leaveObj['user'] = response[i]['user']['firstName'] + " " + response[i]['user']['lastName'];
          leaveObj['reviewedBy'] = response[i]['reviewedBy'];
          leaveObj['status'] = response[i]['status'];
          leaveObj['leaveDetailsId'] = response[i]['leaveDetailsId'];
          leaveObj['sickDocumentUrl'] = response[i]['sickDocumentUrl'];
          if (response[i]['user']['role'] == Role.USER) {
            switch (leaveObj['status']) {
              case LeaveStatus.PENDING:
                leaveObj['lineManager'] = "fa fa-spinner fa-spin text-secondary";
                leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_LINE_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_HR_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_ADMIN:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.DENIED_BY_LINE_MANAGER:
                leaveObj['lineManager'] = "fa fa-times text-danger";
                leaveObj['middleManager'] = "fa fa-times text-danger";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-times text-danger";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_TOP_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_HR_MANAGER:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_ADMIN:
                leaveObj['lineManager'] = "fa fa-check text-success";
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
            }
          } else if (response[i]['user']['role'] == Role.LINE_MANAGER) {
            switch (leaveObj['status']) {
              case LeaveStatus.PENDING:
                leaveObj['middleManager'] = "fa fa-spinner fa-spin text-secondary";
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_MIDDLE_MANAGER:
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_HR_MANAGER:
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_ADMIN:
                leaveObj['middleManager'] = "fa fa-check text-success";
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.DENIED_BY_MIDDLE_MANAGER:
                leaveObj['middleManager'] = "fa fa-times text-danger";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_TOP_MANAGER:
                leaveObj['middleManager'] = "fa fa-times text-success";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_HR_MANAGER:
                leaveObj['middleManager'] = "fa fa-times text-success";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_ADMIN:
                leaveObj['middleManager'] = "fa fa-times text-success";
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
            }
          } else if (response[i]['user']['role'] == Role.MIDDLE_MANAGER) {
            switch (leaveObj['status']) {
              case LeaveStatus.PENDING:
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_HR_MANAGER:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_ADMIN:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.DENIED_BY_TOP_MANAGER:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_HR_MANAGER:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_ADMIN:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
            }
          } else if (response[i]['user']['role'] == Role.TOP_MANAGER) {
            switch (leaveObj['status']) {
              case LeaveStatus.PENDING:
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_HR_MANAGER:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_ADMIN:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.DENIED_BY_HR_MANAGER:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_ADMIN:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
            }
          } else if (response[i]['user']['role'] == Role.HR_MANAGER) {
            switch (leaveObj['status']) {
              case LeaveStatus.PENDING:
                leaveObj['admin'] = "fa fa-spinner fa-spin text-secondary";
                break;
              case LeaveStatus.APPROVED_BY_TOP_MANAGER:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.APPROVED_BY_ADMIN:
                leaveObj['admin'] = "fa fa-check text-success";
                break;
              case LeaveStatus.DENIED_BY_TOP_MANAGER:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
              case LeaveStatus.DENIED_BY_ADMIN:
                leaveObj['admin'] = "fa fa-times text-danger";
                break;
            }
          }
          this.leaves.push(leaveObj);
        }
        const allLeaves = this.leaves.map((obj) => {
          return { ...obj, date: new Date(obj.createdAt) };
        });
        this.dataSource.data = allLeaves.sort(
          (objA, objB) => objB.date.getTime() - objA.date.getTime(),
        );
        this.refreshing = false;
        if (showNotification) {
          this.sendNotification(NotificationType.SUCCESS, `${response.length} leave(s) loaded successfully.`);
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        this.refreshing = false;
      }
    )
  }

  public getHolidayTypes(showNotification: boolean): void {
    this.refreshing = true;
    this.holidayService.getHolidayTypes().subscribe(
      (response: HolidayType[]) => {
        this.holidayTypes = response;
        this.refreshing = false;
        if (showNotification) {
          this.sendNotification(NotificationType.SUCCESS, `${response.length} holiday type(s) loaded successfully.`);
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        this.refreshing = false;
      }
    )

  }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const leaveType = this.form.value['leaveType'];
    const dateFrom = startOfMonth(this.form.value['dateFrom']);
    const dateTo = endOfMonth(this.form.value['dateTo']);
    this.leavesFilter = [];
    for (var i = 0; i < this.leaves.length; i++) {
      let leaveObj = {} as Leave;
      if (leaveType == this.leaves[i]["leaveType"]) {
        if ((new Date(dateFrom) <= new Date(this.leaves[i]["dateFrom"]) && new Date(this.leaves[i]["dateFrom"]) <= new Date(dateTo)) && (new Date(dateFrom) <= new Date(this.leaves[i]["dateTo"]) && new Date(this.leaves[i]["dateTo"]) <= new Date(dateTo))) {
          leaveObj = this.leaves[i];
          this.leavesFilter.push(leaveObj);
        } else if ((new Date(dateFrom) <= new Date(this.leaves[i]["dateFrom"]) && new Date(this.leaves[i]["dateFrom"]) <= new Date(dateTo)) && new Date(this.leaves[i]["dateTo"]) > new Date(dateTo)) {
          leaveObj = this.leaves[i];
          leaveObj['numberDays'] = this.getTotalNumberOfDays(new Date(this.leaves[i]['dateFrom']), new Date(dateTo));
          this.leavesFilter.push(leaveObj);
        } else if (new Date(this.leaves[i]["dateFrom"]) < new Date(dateFrom) && (new Date(dateFrom) <= new Date(this.leaves[i]["dateTo"]) && new Date(this.leaves[i]["dateTo"]) <= new Date(dateTo))) {
          leaveObj = this.leaves[i];
          leaveObj['numberDays'] = this.getTotalNumberOfDays(new Date(dateFrom), new Date(this.leaves[i]['dateTo']));
          this.leavesFilter.push(leaveObj);
        } else if (new Date(this.leaves[i]["dateFrom"]) < new Date(dateFrom) && new Date(this.leaves[i]["dateTo"]) > new Date(dateTo)) {
          leaveObj = this.leaves[i];
          leaveObj['numberDays'] = this.getTotalNumberOfDays(new Date(dateFrom), new Date(dateTo));
          this.leavesFilter.push(leaveObj);
        }
      }
    }
    const allLeavesFilter = this.leavesFilter.map((obj) => {
      return { ...obj, date: new Date(obj.createdAt) };
    });
    this.dataSource.data = allLeavesFilter.sort(
      (objA, objB) => objB.date.getTime() - objA.date.getTime(),
    );
  }

  public downloadAllLeaves(): void {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      delete this.dataSource.data[i].id;
      delete this.dataSource.data[i].lineManager;
      delete this.dataSource.data[i].middleManager;
      delete this.dataSource.data[i].admin;
      delete this.dataSource.data[i].leaveDetailsId;
      delete this.dataSource.data[i].sickDocumentUrl;
    }
    this.exportAsExcelFileService.exportAsExcelFile(this.dataSource.data);
    this.getAllLeaves(false);
  }

  public onSelectLeave(selectedLeave: Leave): void {
    if (selectedLeave['leaveType'] == 'Congé maladie') {
      this.leaveService.getSickDocument(selectedLeave["leaveDetailsId"]).subscribe((responseMessage) => {
        let file = new Blob([responseMessage], { type: 'application/pdf' });
        if (file["size"] != 0) {
          const modalRef = this.modalService.open(SickDocumentviewerComponent, { centered: true, size: 'lg' });
          modalRef.componentInstance.selectedLeave = selectedLeave;
        }
      })
    }
  }

  public getCalendarEvents(showNotification: boolean): void {
    this.holidayService.getCalendarEvents().subscribe(
      (response: CalendarEvent[]) => {
        this.events = [];
        for (let i = 0; i < response.length; i++) {
          let eventsObj = {} as CalendarEvent;
          eventsObj.start = new Date(response[i]['start']);
          eventsObj.end = new Date(response[i]['end']);
          eventsObj.title = response[i]['title'];
          this.events.push(eventsObj);
        }
        if (showNotification) {
          this.sendNotification(NotificationType.SUCCESS, `${response.length} calendarEvent(s) loaded successfully.`);
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
      }
    );
  }

  public getTotalNumberOfDays(dateMin: Date, dateMax: Date): any {
    var numberDays = this.getBusinessDatesCount(new Date(dateMin), new Date(dateMax));
    for (let i = 0; i < this.events.length; i++) {
      const days = this.daysBetween(this.events[i]['start'], this.events[i]['end']);
      days.map(d => {
        if (new Date(d.toString()) > new Date(dateMin) && new Date(d.toString()) < new Date(dateMax)) {
          numberDays -= 1;
        }
      }
      )
    }
    return numberDays;
  }

  public daysBetween(from: Date, to: Date) {
    const fromDate = moment(from).startOf('day');
    const toDate = moment(to).endOf('day');

    const span = moment.duration(toDate.diff(fromDate)).asDays();
    const days = [];
    for (let i = 0; i <= span; i++) {
      days.push(moment(fromDate).add(i, 'day').startOf('day'));
    }
    return days;
  }

  public getBusinessDatesCount(startDate: Date, endDate: Date) {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      if (!((dayOfWeek == 6) || (dayOfWeek == 0))) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000
    }
    return count;
  }

  public getMinDate(event: any) {
    this.dateMin = event.target.value;
  }

  public onDeleteLeaveDetails(index: number): void {
    const modalRef = this.modalService.open(DeleteleavedetailsComponent);
    modalRef.componentInstance.leaveDetailsId = this.dataSource.data[index]['id'];
    modalRef.componentInstance.leaveType = this.dataSource.data[index]['leaveType'];
    modalRef.result.then(
      () => {
        this.getAllLeaves(false);
      },
      () => {
      });
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
