<app-columnone>
    <div class="w-90 m-auto m-5 mt-4 mb-5">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-gold" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        Précédent
                    </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                        Aujourd'hui
                    </div>
                    <div class="btn btn-gold" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                        Prochain
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <!-- <div class="btn-group">
                    <div class="btn btn-gold" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                        Mois
                    </div>
                    <div class="btn btn-gold" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                        Semaine
                    </div>
                    <div class="btn btn-gold" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                        Jour
                    </div>
                </div> -->
            </div>
            <div class="col-md-4">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'fr' }}</h3>
            </div>
        </div>
        <br />
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" locale="fr" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" locale="fr" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" locale="fr" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
        </div>
        <div class="m-1">
            <nz-badge nzColor="#00E5BA" nzText="Terrain/Client...."> &nbsp;&nbsp;</nz-badge>
            <nz-badge nzColor="#FFCBD3" nzText="Maternité leave"> &nbsp;&nbsp;</nz-badge>
            <nz-badge nzColor="#FF5000" nzText="Congé maladie"> &nbsp;&nbsp;</nz-badge>
            <nz-badge nzColor="#802AB7" nzText="Congé annuel"> &nbsp;&nbsp;</nz-badge>
            <nz-badge nzColor="#858585" nzText="Death"> &nbsp;&nbsp;</nz-badge>
            <nz-badge nzColor="#CCDFFF" nzText="WFH"> &nbsp;&nbsp;</nz-badge>
            <nz-badge nzColor="#ad2121" nzText="Others"> &nbsp;&nbsp;</nz-badge>
        </div>
    </div>
</app-columnone>