<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Editer l'événement: {{editCalendarEvent?.title}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <form #editCalendarEventForm="ngForm">
            <div class="form-group">
                <label for="title">Titre</label>
                <input type="text" name="title" required [(ngModel)]="editCalendarEvent.title" class="form-control form-control-sm">
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="colorPrimary">Couleur primaire</label>
                        <input type="color" name="colorPrimary" required [(ngModel)]="editCalendarEvent.color.primary" class="form-control form-control-sm">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="colorSecondary">Couleur secondaire</label>
                        <input type="color" name="colorSecondary" required [(ngModel)]="editCalendarEvent.color.secondary" class="form-control form-control-sm">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="start">Commence à</label>
                <input class="form-control form-control-sm" type="text" mwlFlatpickr [(ngModel)]="editCalendarEvent.start" name="start" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini"
                />
            </div>
            <div class="form-group">
                <label for="end">Fini à</label>
                <input class="form-control form-control-sm" type="text" mwlFlatpickr [(ngModel)]="editCalendarEvent.end" name="end" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Pas encore défini"
                />
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
    <button type="button" (click)="onUpdateCalendarEvent()" [disabled]="editCalendarEventForm.invalid" class="btn btn-gold"><i class="fa fa-floppy-o"></i>&nbsp;&nbsp; Enregistrer les changements</button>
</div>