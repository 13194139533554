import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { Leave } from 'src/app/shared/model/leave';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-approvealldemands',
  templateUrl: './approvealldemands.component.html',
  styleUrls: ['./approvealldemands.component.css']
})
export class ApprovealldemandsComponent implements OnInit {
  @Input() AllleaveDetails: Leave[];
  @Input() reviewedBy: string;
  @Input() role: string;

  constructor(public modal: NgbActiveModal, private leaveService: LeaveService, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onApproveEvent() {
    if (this.role == Role.TOP_MANAGER) {
      for (var i = 0; i < this.AllleaveDetails.length; i++) {
        const formData = new FormData();
        formData.append('currentId', JSON.stringify(this.AllleaveDetails[i].id));
        formData.append('approved', JSON.stringify(true));
        formData.append('rejected', JSON.stringify(false));
        this.leaveService.approvedOrRejectedByAdmin(formData).subscribe(
          (response: Leave) => {
            this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} approved successfully`);
            this.modal.close('Ok click');
          },
          (errorResponse: HttpErrorResponse) => {
            this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          }
        )
      }
    }
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
