<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Editer la ville: {{editCity?.name}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <form #editCityForm="ngForm">
            <div class="form-group">
                <label for="name">Nom</label>
                <input type="text" name="name" required [(ngModel)]="editCity.name" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="code">Code</label>
                <input type="text" name="code" required [(ngModel)]="editCity.code" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="region">Région</label>
                <input type="text" name="region" required [(ngModel)]="editCity.region" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="nativeName">Nom d'origine</label>
                <input type="text" name="nativeName" required [(ngModel)]="editCity.nativeName" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="countryName">Pays</label>
                <select name="countryName" required [(ngModel)]="editCity.countryName" [formControl]='countrySelected' class="form-select form-select-sm">
                    <option *ngFor="let country of countries" [value]="country.name">
                        {{ country.name }}
                    </option>
                </select>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
    <button type="button" (click)="onUpdateCity()" [disabled]="editCityForm.invalid" class="btn btn-gold"><i class="fa fa-floppy-o"></i>&nbsp;&nbsp; Enregistrer les changements</button>
</div>