import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangepasswordComponent } from './authentication/components/changepassword/changepassword.component';
import { LoginComponent } from './authentication/components/login/login.component';
import { ResetpasswordComponent } from './authentication/components/resetpassword/resetpassword.component';
import { AdministrativerequestComponent } from './controlpanel/components/administrativerequest/administrativerequest.component';
import { HolidaysComponent } from './controlpanel/components/holidays/holidays.component';
import { AccessmanagementComponent } from './controlpanel/components/accessmanagement/accessmanagement.component';
import { AdduserindividualComponent } from './controlpanel/components/adduserindividual/adduserindividual.component';
import { EdituserComponent } from './controlpanel/components/edituser/edituser.component';
import { PoliticsComponent } from './controlpanel/components/politics/politics.component';
import { ReportsComponent } from './controlpanel/components/reports/reports.component';
import { RessourcesComponent } from './controlpanel/components/ressources/ressources.component';
import { ProfileComponent } from './shared/components/profile/profile.component';
import { AuthenticationGuard } from './shared/guard/authentication.guard';
import { TeamComponent } from './controlpanel/components/team/team.component';
import { RegisterComponent } from './authentication/components/register/register.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { Role } from './shared/enum/role.enum';
import { DashboardComponent } from './controlpanel/components/dashboard/dashboard.component';
import { HistoryleaveComponent } from './controlpanel/components/historyleave/historyleave.component';
import { ManagerhistoryleaveComponent } from './controlpanel/components/managerhistoryleave/managerhistoryleave.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', redirectTo: "login", pathMatch: "full" },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'controlpanel/changepassword', component: ChangepasswordComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.USER, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/editprofile', component: ProfileComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.USER, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.USER, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  // { path: 'controlpanel/resources', component: RessourcesComponent, canActivate: [AuthenticationGuard], data: { [Role.ADMIN, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/politics', component: PoliticsComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  // { path: 'controlpanel/reports', component: ReportsComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.USER, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/historyleaves', component: HistoryleaveComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/managerhistoryleaves', component: ManagerhistoryleaveComponent, canActivate: [AuthenticationGuard], data: { role: [Role.MIDDLE_MANAGER, Role.LINE_MANAGER] } },
  { path: 'controlpanel/addnewuser', component: AdduserindividualComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/edituser/:username', component: EdituserComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/accessmanagement', component: AccessmanagementComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/team', component: TeamComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER]} },
  { path: 'controlpanel/holidays', component: HolidaysComponent, canActivate: [AuthenticationGuard], data: { role: [Role.USER, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: 'controlpanel/administrativerequest', component: AdministrativerequestComponent, canActivate: [AuthenticationGuard], data: { role: [Role.ADMIN, Role.USER, Role.LINE_MANAGER, Role.MIDDLE_MANAGER, Role.TOP_MANAGER, Role.HR_MANAGER] } },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
