import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { EventColor } from 'calendar-utils';
import { Subject } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Resizable } from 'src/app/shared/model/resizable';
import { HolidayService } from 'src/app/shared/service/holiday.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { Leave } from 'src/app/shared/model/leave';
import { LeaveStatus } from 'src/app/shared/enum/leave_status.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public modalData: {
    action: string;
    event: CalendarEvent;
  };
  public refresh = new Subject<void>();
  public events: Array<CalendarEvent> = [];
  public activeDayIsOpen: boolean = false;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  constructor(private holidayService: HolidayService, public datepipe: DatePipe, private leaveService: LeaveService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getCalendarEvents();
  }

  public getCalendarEvents(): void {
    this.leaveService.getAllLeaves().subscribe(
      (response: Leave[]) => {
        this.events = [];
        for (let i = 0; i < response.length; i++) {
          if (response[i]["status"] == LeaveStatus.APPROVED_BY_ADMIN || response[i]["status"] == LeaveStatus.APPROVED_BY_HR_MANAGER || response[i]["status"] == LeaveStatus.APPROVED_BY_TOP_MANAGER) {
            let eventsObj = {} as CalendarEvent;
            let colorObj = {} as EventColor;
            eventsObj.start = new Date(response[i]['dateFrom']);
            eventsObj.end = new Date(response[i]['dateTo']);
            eventsObj.title = response[i]['user']["firstName"] + " " + response[i]['user']["lastName"] + " - " + response[i]["leaveType"];
            switch (response[i]['leaveType']) {
              case "Congé annuel":
                colorObj.primary = "#802AB7";
                colorObj.secondary = "#802AB7";
                eventsObj.color = colorObj;
                break;
              case "Congé maladie":
                colorObj.primary = "#FF5000";
                colorObj.secondary = "#FF5000";
                eventsObj.color = colorObj;
                break;
              case "WFH":
                colorObj.primary = "#CCDFFF";
                colorObj.secondary = "#CCDFFF";
                eventsObj.color = colorObj;
                break;
              case "Maternité leave":
                colorObj.primary = "#FFCBD3";
                colorObj.secondary = "#FFCBD3";
                eventsObj.color = colorObj;
                break;
              case "Death":
                colorObj.primary = "#858585";
                colorObj.secondary = "#858585";
                eventsObj.color = colorObj;
                break;
              case "Terrain/Client....":
                colorObj.primary = "#00E5BA";
                colorObj.secondary = "#00E5BA";
                eventsObj.color = colorObj;
                break;
              default:
                colorObj.primary = "#ad2121";
                colorObj.secondary = "#FAE3E3";
                eventsObj.color = colorObj;
                break;
            }
            this.events.push(eventsObj);
          }
        }
        this.holidayService.getCalendarEvents().subscribe(
          (response: CalendarEvent[]) => {
            for (let i = 0; i < response.length; i++) {
              let eventsObj = {} as CalendarEvent;
              let colorObj = {} as EventColor;
              eventsObj.start = new Date(response[i]['start']);
              eventsObj.end = new Date(response[i]['end']);
              eventsObj.title = response[i]['title'];
              colorObj.primary = response[0]['colorPrimary'];
              colorObj.secondary = response[0]['colorSecondary'];
              eventsObj.color = colorObj;
              this.events.push(eventsObj);
            }
          },
          (errorResponse: HttpErrorResponse) => {
            this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          }
        )
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
      }
    )
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
