export enum LeaveStatus {
    PENDING = 'PENDING',
    APPROVED_BY_LINE_MANAGER = 'APPROVED_BY_LINE_MANAGER',
    APPROVED_BY_MIDDLE_MANAGER = 'APPROVED_BY_MIDDLE_MANAGER', 
    APPROVED_BY_TOP_MANAGER = 'APPROVED_BY_TOP_MANAGER',
    APPROVED_BY_HR_MANAGER = 'APPROVED_BY_HR_MANAGER', 
    APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN', 
    DENIED_BY_LINE_MANAGER = 'DENIED_BY_LINE_MANAGER',
    DENIED_BY_MIDDLE_MANAGER = 'DENIED_BY_MIDDLE_MANAGER',
    DENIED_BY_TOP_MANAGER = 'DENIED_BY_TOP_MANAGER',
    DENIED_BY_HR_MANAGER = 'DENIED_BY_HR_MANAGER',
    DENIED_BY_ADMIN = 'DENIED_BY_ADMIN',
  }
  