import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { Leave } from 'src/app/shared/model/leave';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-rejectdemand',
  templateUrl: './rejectdemand.component.html',
  styleUrls: ['./rejectdemand.component.css']
})
export class RejectdemandComponent implements OnInit {
  @Input() leaveDetails: Leave;
  @Input() reviewedBy: string;
  @Input() role: string;

  constructor(public modal: NgbActiveModal, private leaveService: LeaveService, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  onRejectEvent() {
    if (this.role == Role.ADMIN) {
      const formData = new FormData();
      formData.append('currentId', JSON.stringify(this.leaveDetails.id));
      formData.append('approved', JSON.stringify(false));
      formData.append('rejected', JSON.stringify(true));
      this.leaveService.approvedOrRejectedByAdmin(formData).subscribe(
        (response: Leave) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} rejected successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    } else if (this.role == Role.TOP_MANAGER) {
      const formData = new FormData();
      formData.append('currentId', JSON.stringify(this.leaveDetails.id));
      formData.append('approved', JSON.stringify(false));
      formData.append('rejected', JSON.stringify(true));
      this.leaveService.approvedOrRejectedByTopManager(formData).subscribe(
        (response: Leave) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} rejected successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    } else if (this.role == Role.HR_MANAGER) {
      const formData = new FormData();
      formData.append('currentId', JSON.stringify(this.leaveDetails.id));
      formData.append('approved', JSON.stringify(false));
      formData.append('rejected', JSON.stringify(true));
      this.leaveService.approvedOrRejectedByHRManager(formData).subscribe(
        (response: Leave) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} rejected successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    } else if (this.role == Role.MIDDLE_MANAGER) {
      const formData = new FormData();
      formData.append('currentId', JSON.stringify(this.leaveDetails.id));
      formData.append('reviewedBy', this.reviewedBy);
      formData.append('approved', JSON.stringify(false));
      formData.append('rejected', JSON.stringify(true));
      this.leaveService.approvedOrRejectedByMiddleManager(formData).subscribe(
        (response: Leave) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} rejected successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    } else if (this.role == Role.LINE_MANAGER) {
      const formData = new FormData();
      formData.append('currentId', JSON.stringify(this.leaveDetails.id));
      formData.append('reviewedBy', this.reviewedBy);
      formData.append('approved', JSON.stringify(false));
      formData.append('rejected', JSON.stringify(true));
      this.leaveService.approvedOrRejectedByLineManager(formData).subscribe(
        (response: Leave) => {
          this.sendNotification(NotificationType.SUCCESS, `${response.leaveType} rejected successfully`);
          this.modal.close('Ok click');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        }
      )
    }
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

}
