<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Nouveau Groupe</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <form #groupUserForm="ngForm" class="form">
            <p class="font-weight-bold" style="text-align: center;"> Selectionner le fichier à importer </p>
            <div class="row">
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text"> Importer fichier </span>
                    </div>
                    <div class="custom-file">
                        <input type="file" name="customFile" (change)="onExcelFileChange($event)" #inputFile class="custom-file-input" ngModel accept=".xlsx, .xls">
                        <label class="custom-file-label">
                            <span [hidden]="!fileName">{{fileName}}</span>
                            <span [hidden]="fileName">Choisir un fichier</span>
                        </label>
                    </div>
                </div>
            </div>
            <p>Format supporté: XSLX, XLS</p>
        </form>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col d-flex justify-content-end">
            <button type="button" class="btn btn-secondary form-control-sm" (click)="downloadFile()">
                <i class="fa fa-download"></i>
                <span> Télécharger un exemple</span>
            </button>
            <button type="button" class="btn btn-gold form-control-sm ml-2" [disabled]="!isExcelFile" (click)="onUploadUsers()">
                <i *ngIf="refreshing" class="fa fa-spinner fa-spin"></i>
                <i *ngIf="!refreshing" class="fa fa-floppy-o"></i>&nbsp;&nbsp;
                <span *ngIf="refreshing">chargement...</span>
                <span *ngIf="!refreshing">Importer</span>
            </button>
        </div>
    </div>
</div>