<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Editer la demande: {{ editRequest?.object }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <form #editRequestForm="ngForm">
            <div class="form-group">
                <label for="objects">Objet de la demande</label>
                <input readonly="true" type="text" name="objects" required [(ngModel)]="editRequest.object" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="emergency">Degré d'urgence</label>
                <input readonly="true" type="text" name="emergency" required [(ngModel)]="editRequest.emergency" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="status">Status</label>
                <select name="status" required [(ngModel)]="editRequest.status" class="form-select form-select-sm">
                    <option value="En attente">En attente</option>
                    <option value="Traité">Traité</option>
                </select>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
    <button type="button" (click)="onUpdateAdministrativeRequest()" [disabled]="editRequestForm.invalid" class="btn btn-gold"><i class="fa fa-floppy-o"></i>&nbsp;&nbsp; Enregistrer les changements</button>
</div>