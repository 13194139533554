import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LeaveStatus } from 'src/app/shared/enum/leave_status.enum';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { Role } from 'src/app/shared/enum/role.enum';
import { Leave } from 'src/app/shared/model/leave';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { LeaveService } from 'src/app/shared/service/leave.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { UserService } from 'src/app/shared/service/user.service';
import { ApprovealldemandsComponent } from '../modals/approvealldemands/approvealldemands.component';
import { ApprovedemandComponent } from '../modals/approvedemand/approvedemand.component';
import { RejectdemandComponent } from '../modals/rejectdemand/rejectdemand.component';
import { SickDocumentviewerComponent } from '../modals/sick-documentviewer/sick-documentviewer.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit, OnDestroy {
  public refreshing!: boolean;
  public user: User;
  private subscriptions: Subscription[] = [];
  public leaves: Array<Leave> = [];
  public reviewedBy: string;

  displayedColumns: string[] = ['name', 'leaveType', 'date', 'days', 'actions'];
  public dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private authenticationService: AuthenticationService, private leaveService: LeaveService, private modalService: NgbModal, private notificationService: NotificationService, private userService: UserService) { }

  ngOnInit(): void {
    this.user = this.authenticationService.getUserFromLocalCache();
    if (this.user.role == Role.HR_MANAGER) {
      this.userService.getUsers().subscribe(
        (response: User[]) => {
          this.leaves = [];
          for (let i = 0; i < response.length; i++) {
            if (response[i]['role'] == Role.TOP_MANAGER) {
              this.reviewedBy = response[i]["username"];
              this.leaveService.getLeavesByReviewedBy(this.reviewedBy).subscribe(
                (response: Leave[]) => {
                  for (let i = 0; i < response.length; i++) {
                    let leavesObj = {} as Leave;
                    if (this.user.role == Role.HR_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.APPROVED_BY_MIDDLE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
                      leavesObj = response[i];
                      this.leaves.push(leavesObj);
                    }
                  }
                  const leaves = this.leaves.map((obj) => {
                    return { ...obj, date: new Date(obj.createdAt) };
                  });
                  this.dataSource.data = leaves.sort(
                    (objA, objB) => objB.date.getTime() - objA.date.getTime(),
                  );
                  this.refreshing = false;
                },
                (errorResponse: HttpErrorResponse) => {
                  this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
                  this.refreshing = false;
                }
              )
            }
          }
          this.reviewedBy = this.user["username"];
          this.leaveService.getLeavesByReviewedBy(this.reviewedBy).subscribe(
            (response: Leave[]) => {
              for (let i = 0; i < response.length; i++) {
                let leavesObj = {} as Leave;
                if (this.user.role == Role.HR_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.APPROVED_BY_MIDDLE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
                  leavesObj = response[i];
                  this.leaves.push(leavesObj);
                }
              }
              const leaves = this.leaves.map((obj) => {
                return { ...obj, date: new Date(obj.createdAt) };
              });
              this.dataSource.data = leaves.sort(
                (objA, objB) => objB.date.getTime() - objA.date.getTime(),
              );
              this.refreshing = false;
            },
            (errorResponse: HttpErrorResponse) => {
              this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
              this.refreshing = false;
            }
          )
        }
      )
    } else {
      this.reviewedBy = this.user['username'];
    }
    this.leaveService.getLeavesByReviewedBy(this.reviewedBy).subscribe(
      (response: Leave[]) => {
        this.leaves = [];
        for (let i = 0; i < response.length; i++) {
          let leavesObj = {} as Leave;
          if (this.user.role == Role.ADMIN && response[i]['status'] == LeaveStatus.PENDING) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          } else if (this.user.role == Role.TOP_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.APPROVED_BY_MIDDLE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          } else if (this.user.role == Role.MIDDLE_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          } else if (this.user.role == Role.LINE_MANAGER && response[i]['status'] == LeaveStatus.PENDING) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          }
        }
        const leaves = this.leaves.map((obj) => {
          return { ...obj, date: new Date(obj.createdAt) };
        });
        this.dataSource.data = leaves.sort(
          (objA, objB) => objB.date.getTime() - objA.date.getTime(),
        );
        this.refreshing = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        this.refreshing = false;
      }
    )
  }

  public getLeavesByReviewedBy(): void {
    if (this.user.role == Role.HR_MANAGER) {
      this.userService.getUsers().subscribe(
        (response: User[]) => {
          this.leaves = [];
          for (let i = 0; i < response.length; i++) {
            if (response[i]['role'] == Role.TOP_MANAGER) {
              this.reviewedBy = response[i]["username"];
              this.leaveService.getLeavesByReviewedBy(this.reviewedBy).subscribe(
                (response: Leave[]) => {
                  for (let i = 0; i < response.length; i++) {
                    let leavesObj = {} as Leave;
                    if (this.user.role == Role.HR_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.APPROVED_BY_MIDDLE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
                      leavesObj = response[i];
                      this.leaves.push(leavesObj);
                    }
                  }
                  const leaves = this.leaves.map((obj) => {
                    return { ...obj, date: new Date(obj.createdAt) };
                  });
                  this.dataSource.data = leaves.sort(
                    (objA, objB) => objB.date.getTime() - objA.date.getTime(),
                  );
                  this.refreshing = false;
                },
                (errorResponse: HttpErrorResponse) => {
                  this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
                  this.refreshing = false;
                }
              )
            }
          }
          this.reviewedBy = this.user["username"];
          this.leaveService.getLeavesByReviewedBy(this.reviewedBy).subscribe(
            (response: Leave[]) => {
              for (let i = 0; i < response.length; i++) {
                let leavesObj = {} as Leave;
                if (this.user.role == Role.HR_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.APPROVED_BY_MIDDLE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
                  leavesObj = response[i];
                  this.leaves.push(leavesObj);
                }
              }
              const leaves = this.leaves.map((obj) => {
                return { ...obj, date: new Date(obj.createdAt) };
              });
              this.dataSource.data = leaves.sort(
                (objA, objB) => objB.date.getTime() - objA.date.getTime(),
              );
              this.refreshing = false;
            },
            (errorResponse: HttpErrorResponse) => {
              this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
              this.refreshing = false;
            }
          )
        }
      )
    } else {
      this.reviewedBy = this.user['username'];
    }
    this.leaveService.getLeavesByReviewedBy(this.reviewedBy).subscribe(
      (response: Leave[]) => {
        this.leaves = [];
        for (let i = 0; i < response.length; i++) {
          let leavesObj = {} as Leave;
          if (this.user.role == Role.ADMIN && response[i]['status'] == LeaveStatus.PENDING) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          } else if (this.user.role == Role.TOP_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.APPROVED_BY_MIDDLE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          } else if (this.user.role == Role.MIDDLE_MANAGER && (response[i]['status'] == LeaveStatus.APPROVED_BY_LINE_MANAGER || response[i]['status'] == LeaveStatus.PENDING)) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          } else if (this.user.role == Role.LINE_MANAGER && response[i]['status'] == LeaveStatus.PENDING) {
            leavesObj = response[i];
            this.leaves.push(leavesObj);
          }
        }
        const leaves = this.leaves.map((obj) => {
          return { ...obj, date: new Date(obj.createdAt) };
        });
        this.dataSource.data = leaves.sort(
          (objA, objB) => objB.date.getTime() - objA.date.getTime(),
        );
        this.refreshing = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
        this.refreshing = false;
      }
    )
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public onApproveDemand(index: number): void {
    const modalRef = this.modalService.open(ApprovedemandComponent);
    modalRef.componentInstance.leaveDetails = this.dataSource.data[index];
    if (this.user.role != Role.ADMIN) {
      modalRef.componentInstance.reviewedBy = this.user['manager']['username'];
    }
    modalRef.componentInstance.role = this.user['role'];
    modalRef.result.then(
      () => {
        this.getLeavesByReviewedBy();
      },
      () => {
      });
  }

  public onRejectDemand(index: number): void {
    const modalRef = this.modalService.open(RejectdemandComponent);
    modalRef.componentInstance.leaveDetails = this.dataSource.data[index];
    if (this.user.role != Role.ADMIN) {
      modalRef.componentInstance.reviewedBy = this.user['manager']['username'];
    }
    modalRef.componentInstance.role = this.user['role'];
    modalRef.result.then(
      () => {
        this.getLeavesByReviewedBy();
      },
      () => {
        this.getLeavesByReviewedBy();
      });
  }

  public onSelectLeave(selectedLeave: Leave): void {
    if (selectedLeave['leaveType'] == 'Congé maladie') {
      const modalRef = this.modalService.open(SickDocumentviewerComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.selectedLeave = selectedLeave;
    }
  }

  public approveAllDemands(): void {
    const modalRef = this.modalService.open(ApprovealldemandsComponent);
    modalRef.componentInstance.AllleaveDetails = this.dataSource.data;
    if (this.user.role != Role.ADMIN) {
      modalRef.componentInstance.reviewedBy = this.user['manager']['username'];
    }
    modalRef.componentInstance.role = this.user['role'];
    modalRef.result.then(
      () => {
        this.getLeavesByReviewedBy();
      },
      () => {
      });
  }

  public get isTopManager(): boolean {
    return this.getUserRole() === Role.TOP_MANAGER;
  }

  private getUserRole(): string {
    return this.authenticationService.getUserFromLocalCache().role;
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
