<app-columnone>
    <div class="m-2">
        <nav class="navbar navbar-expand-md breadcrumb">
            <div class="collapse navbar-collapse" id="navbarCollapse" style="background-color: #fafafa;">
                <div class="nav nav-pills ml-5">
                    <a class="nav-item nav-link breadcrumb-item" (click)="getCountries(true)" data-toggle="tab" href="#countries">Pays</a>
                    <a class="nav-item nav-link breadcrumb-item" (click)="getCities(true)" data-toggle="tab" href="#cities">Villes</a>
                </div>
            </div>
        </nav>
    </div>

    <!-- main content -->
    <div class="tab-content mt-3" id="myTabContent">

        <!-- countries table -->
        <div class="tab-pane fade show active" id="countries">
            <div class="mat-elevation-z8 w-90 m-auto mt-5" id="countries">
                <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
                    <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                        <ul class="navbar-nav text-md-center text-left">
                        </ul>
                        <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                            <!-- Search form -->
                            <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                                <input class="form-control form-control-sm mr-sm-2 m-1" type="search" placeholder="Rechercher..." aria-label="Search" #input (keyup)="applyFilterCountry($event)">
                            </form>
                            <button type="button" class="btn btn-gold m-1 btn-sm" (click)="createNewCountry()"><i class="fa fa-plus"></i> Nouveau pays</button>
                            <button type="button" class="btn btn-gold m-1 btn-sm" (click)="getCountries(true)">
                            <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i><i *ngIf="!refreshing" class="fa fa-refresh"></i>
                      </button>
                        </ul>
                    </div>
                </nav>
                <table mat-table [dataSource]="dataSourceCountry" matSort>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Nom </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="capital">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Capitale </th>
                        <td mat-cell *matCellDef="let row"> {{row.capital}} </td>
                    </ng-container>

                    <ng-container matColumnDef="currency">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Devise </th>
                        <td mat-cell *matCellDef="let row">{{row.currency}} </td>
                    </ng-container>

                    <ng-container matColumnDef="phoneCode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Indicatif Tél.</th>
                        <td mat-cell *matCellDef="let row"> {{row.phoneCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="iso_2_code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Iso 2 code </th>
                        <td mat-cell *matCellDef="let row">{{row.iso_2_code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nativeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Nom d'origine </th>
                        <td mat-cell *matCellDef="let row"> {{row.nativeName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Actions </th>
                        <td mat-cell *matCellDef="let i = index">
                            <i class="fa fa-edit btn btn-gold" (click)="onEditCountry(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                            <i class="fa fa-trash btn btn-gold ml-1" (click)="onDeleteCountry(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCountry"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCountry;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="7">Aucune donnée ne correspond à ce filtre: "{{input.value}}"</td>
                    </tr>
                </table>

                <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of countries"></mat-paginator>
            </div>
        </div>

        <!-- cities table -->
        <div class="tab-pane fade show" id="cities">
            <div class="mat-elevation-z8 w-90 m-auto mt-5" id="cities">
                <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
                    <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                        <ul class="navbar-nav text-md-center text-left">
                        </ul>
                        <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                            <!-- Search form -->
                            <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                                <input class="form-control form-control-sm mr-sm-2 m-1" type="search" placeholder="Rechercher..." aria-label="Search" #input (keyup)="applyFilterCity($event)">
                            </form>
                            <button type="button" class="btn btn-gold m-1 btn-sm" (click)="createNewCity()"><i class="fa fa-plus"></i> Nouvelle ville</button>
                            <button type="button" class="btn btn-gold m-1 btn-sm" (click)="getCities(true)">
                            <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i><i *ngIf="!refreshing" class="fa fa-refresh"></i>
                      </button>
                        </ul>
                    </div>
                </nav>
                <table mat-table [dataSource]="dataSourceCity" matSort>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Nom </th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Code </th>
                        <td mat-cell *matCellDef="let row"> {{row.code}} </td>
                    </ng-container>

                    <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Région </th>
                        <td mat-cell *matCellDef="let row">{{row.region}} </td>
                    </ng-container>

                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Pays </th>
                        <td mat-cell *matCellDef="let row"> {{row.country.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nativeName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Nom d'origine </th>
                        <td mat-cell *matCellDef="let row"> {{row.nativeName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Actions </th>
                        <td mat-cell *matCellDef="let i = index">
                            <i class="fa fa-edit btn btn-gold" (click)="onEditCity(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                            <i class="fa fa-trash btn btn-gold ml-1" (click)="onDeleteCity(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCity"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCity;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="6">Aucune données ne correspond à ce filtre: "{{input.value}}"</td>
                    </tr>
                </table>

                <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of cities"></mat-paginator>
            </div>
        </div>

    </div>
</app-columnone>