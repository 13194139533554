import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { User } from 'src/app/shared/model/user';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  public refreshing: boolean;
  private subscriptions: Subscription[] = [];
  form!: FormGroup;
  submitted = false;

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private notificationService: NotificationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.authenticationService.isUserLoggedIn()) {
      this.router.navigateByUrl('/controlpanel/dashboard');
    }
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public onRegister(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.refreshing = true;
    this.subscriptions.push(
      this.authenticationService.register(this.form.value).subscribe(
        (response: User) => {
          this.refreshing = false;
          this.sendNotification(NotificationType.SUCCESS, `A new account was created for ${response.username}.
          Please check your email for password to log in.`);
          this.router.navigateByUrl('/login');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
