export class User {
  public userId: string;
  public firstName: string;
  public lastName: string;
  public username: string;
  public email: string;
  public phoneNumber: string;
  public phoneNumberEmergency: string;
  public fullNameEmergency: string;
  public homeAddress: string;
  public cin: string;
  public cnss: string;
  public numberDaysLeave: string;
  public statusEmployer: string;
  public statusMarital: string;
  public dateBirth: string;
  public placeBirth: string;
  public nationality: string;
  public dateRecruitment: string;
  public lastLoginDate: Date | null;
  public lastLoginDateDisplay: Date | null;
  public joinDate: Date | null;
  public profileImageUrl: string;
  public affiliationRetirement: string;
  public active: boolean;
  public notLocked: boolean;
  public role: string;
  public authorities: [];
  public managerUsername: string;
  public healthMembership: string;
  public incremental: string;
  public dateDeactivation: string;
  public function: string;
  public manager: User;

  constructor() {
    this.userId = '';
    this.firstName = '';
    this.lastName = '';
    this.username = '';
    this.email = '';
    this.phoneNumber = '';
    this.phoneNumberEmergency = '';
    this.fullNameEmergency = '';
    this.homeAddress = '';
    this.cin = '';
    this.cnss = '';
    this.numberDaysLeave = '';
    this.statusEmployer = '';
    this.statusMarital = '';
    this.dateBirth = '';
    this.placeBirth = '';
    this.nationality = '';
    this.dateRecruitment = '';
    this.lastLoginDate = null;
    this.lastLoginDateDisplay = null;
    this.joinDate = null;
    this.profileImageUrl = '';
    this.affiliationRetirement = '';
    this.active = false;
    this.notLocked = false;
    this.role = '';
    this.authorities = [];
    this.managerUsername = '';
    this.healthMembership = '';
    this.incremental = '';
    this.dateDeactivation = '';
    this.function = '';
    this.manager = null;
  }

}
