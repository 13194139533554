import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessmanagementComponent } from './components/accessmanagement/accessmanagement.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AdduserComponent } from './components/modals/adduser/adduser.component';
import { AddusergroupComponent } from './components/modals/addusergroup/addusergroup.component';
import { AdduserindividualComponent } from './components/adduserindividual/adduserindividual.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DeleteuserComponent } from './components/modals/deleteuser/deleteuser.component';
import { EdituserComponent } from './components/edituser/edituser.component';
import { InfouserComponent } from './components/modals/infouser/infouser.component';
import { RessourcesComponent } from './components/ressources/ressources.component';
import { AddresourceComponent } from './components/modals/addresource/addresource.component';
import { DeleteresourceComponent } from './components/modals/deleteresource/deleteresource.component';
import { EditcityComponent } from './components/modals/editresource/editcity/editcity.component';
import { EditcountryComponent } from './components/modals/editresource/editcountry/editcountry.component';
import { PoliticsComponent } from './components/politics/politics.component';
import { AddcalendareventComponent } from './components/modals/addcalendarevent/addcalendarevent.component';
import { DeletecalendareventComponent } from './components/modals/deletecalendarevent/deletecalendarevent.component';
import { EditcalendareventComponent } from './components/modals/editcalendarevent/editcalendarevent.component';
import { AddholidaytypeComponent } from './components/modals/addholidaytype/addholidaytype.component';
import { UpdateholidaytypeComponent } from './components/modals/updateholidaytype/updateholidaytype.component';
import { DeleteholidaytypeComponent } from './components/modals/deleteholidaytype/deleteholidaytype.component';
import { ReportsComponent } from './components/reports/reports.component';
import { DeletereportComponent } from './components/modals/deletereport/deletereport.component';
import { HolidaysComponent } from './components/holidays/holidays.component';
import { AdministrativerequestComponent } from './components/administrativerequest/administrativerequest.component';
import { TeamComponent } from './components/team/team.component';
import { EditadministrativerequestComponent } from './components/modals/editadministrativerequest/editadministrativerequest.component';
import { ApprovedemandComponent } from './components/modals/approvedemand/approvedemand.component';
import { RejectdemandComponent } from './components/modals/rejectdemand/rejectdemand.component';
import { SickDocumentviewerComponent } from './components/modals/sick-documentviewer/sick-documentviewer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HistoryleaveComponent } from './components/historyleave/historyleave.component';
import { DeleteleavedetailsComponent } from './components/modals/deleteleavedetails/deleteleavedetails.component';
import { ManagerhistoryleaveComponent } from './components/managerhistoryleave/managerhistoryleave.component';
import { ApprovealldemandsComponent } from './components/modals/approvealldemands/approvealldemands.component';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

const antdModule = [
  NzFormModule,
  NzPageHeaderModule,
  NzInputNumberModule,
  NzTableModule,
  NzInputModule,
  NzSwitchModule,
  NzButtonModule,
  NzDividerModule,
  NzCardModule,
  NzCheckboxModule,
  NzSelectModule,
  NzIconModule,
  NzResultModule,
  NzBadgeModule,
  NzToolTipModule,
  NzUploadModule,
  NzBreadCrumbModule,
  NzMessageModule
]

@NgModule({
  declarations: [
    AccessmanagementComponent, 
    AdduserComponent, 
    AddusergroupComponent, 
    AdduserindividualComponent, 
    DeleteuserComponent, 
    EdituserComponent, 
    InfouserComponent, 
    RessourcesComponent, 
    AddresourceComponent, 
    DeleteresourceComponent, 
    EditcityComponent, 
    EditcountryComponent, 
    PoliticsComponent, 
    AddcalendareventComponent, 
    DeletecalendareventComponent, 
    EditcalendareventComponent, 
    AddholidaytypeComponent, 
    UpdateholidaytypeComponent, 
    DeleteholidaytypeComponent, 
    ReportsComponent, 
    DeletereportComponent,
    HolidaysComponent,
    AdministrativerequestComponent,
    TeamComponent,
    EditadministrativerequestComponent,
    ApprovedemandComponent,
    RejectdemandComponent,
    SickDocumentviewerComponent,
    DashboardComponent,
    HistoryleaveComponent,
    DeleteleavedetailsComponent,
    ManagerhistoryleaveComponent,
    ApprovealldemandsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    RouterModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    SharedModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ...antdModule
  ],
  exports: [PoliticsComponent],
})
export class ControlpanelModule { }
