<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{selectedUser?.firstName}} {{selectedUser?.lastName}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-auto">
                        <div class="mx-auto" style="width: 120px;">
                            <div class="d-flex justify-content-center align-items-center rounded">
                                <img class="rounded" height="120" width="120" src="{{selectedUser?.profileImageUrl}}" alt="{{selectedUser?.firstName}}">
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex flex-column flex-sm-row justify-content-between">
                        <div class="text-center text-sm-left mb-sm-0">
                            <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">{{selectedUser?.firstName}} {{selectedUser?.lastName}}
                            </h4>
                            <p class="mb-1">{{selectedUser?.username}}</p>
                            <div class="">Statut:
                                <span [hidden]="!selectedUser?.active" class="badge alert-success">Actif</span>
                                <span [hidden]="selectedUser?.active" class="badge alert-danger">Inactif</span>
                            </div>
                            <div *ngIf="selectedUser?.lastLoginDateDisplay" class="text-muted"><small>Dernière connexion:
                    {{selectedUser?.lastLoginDateDisplay | date: 'medium'}}</small>
                            </div>
                        </div>
                        <div class="text-center text-sm-right">
                            <div class="text-muted"><small>A rejoint le: {{selectedUser?.joinDate | date: 'mediumDate'}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"></li>
                <li class="list-group-item"><i class="fa fa-id-badge float-right"></i> {{selectedUser?.userId}}
                </li>
                <li class="list-group-item"><i class="fa fa-envelope float-right"></i> {{selectedUser?.email}}
                </li>
                <li class="list-group-item"><i class="fa fa-phone float-right"></i> {{selectedUser?.phoneNumber}}
                </li>
                <li class="list-group-item"><i class="fa fa-user float-right"></i> {{selectedUser?.managerUsername}}
                </li>
                <li class="list-group-item"><i class="fas fa-shield-alt float-right"></i>{{selectedUser?.role.substring(5)}}
                </li>
                <li *ngIf="selectedUser?.lastLoginDateDisplay" class="list-group-item">
                    <i class="fas fa-sign-in-alt float-right"></i>{{selectedUser?.lastLoginDateDisplay | date: 'medium'}}
                </li>
                <li class="list-group-item">
                    <span [hidden]="selectedUser?.notLocked"><i class="fa fa-lock float-right" style="color: red;"></i>Account Locked</span>
                    <span [hidden]="!selectedUser?.notLocked"><i class="fa fa-unlock float-right" style="color: gold;"></i>Account Unlocked</span>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-gold" (click)="activeModal.close('Close click')">Fermer</button>
</div>