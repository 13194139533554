<div class="container-fluid h-100">
    <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
        <div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5">
                    <nz-card class="bg-border">
                        <h2 class="m-t-20">Connexion</h2>
                        <form nz-form [formGroup]="loginForm" nzLayout="vertical" class="login-form" (ngSubmit)="onLogin(loginForm.value)">
                            <nz-form-item>
                                <nz-form-label nzRequired nzFor="username">Nom d'utilisateur</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group [nzPrefix]="prefixUser">
                                        <input type="text" nz-input formControlName="username" placeholder="Username">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="relative">
                                <nz-form-label nzRequired nzFor="password">Mot de passe</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your Password!">
                                    <nz-input-group [nzPrefix]="prefixLock">
                                        <input type="password" nz-input formControlName="password" placeholder="Password">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-control>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="font-size-13 text-muted">
                                            <a class="float-right font-size-13 text-muted"
                                            (click)="onResetPassword()">J'ai oublié mon mot de passe</a></span>
                                        <button nz-button class="login-form-button btn btn-gold">Connexion</button>
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                        </form>
                        <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
                        <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
                    </nz-card>
                </div>
                <div class="offset-md-1 col-md-6 d-none d-md-block">
                    <img class="img-fluid" src="assets/images/login.png" alt="">
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</div>