<app-columnone>
    <!-- users table -->
    <div class="mat-elevation-z8 w-90 m-auto mt-5">
        <nav class="navbar navbar-light navbar-expand-md bg-gray justify-content-md-center justify-content-start">
            <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                <ul class="navbar-nav text-md-center text-left">
                </ul>
                <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                    <!-- Search form -->
                    <form class="form-inline my-2 my-lg-0 justify-content-center m-1">
                        <input class="form-control form-control-sm mr-sm-2 m-1" type="search" aria-label="Search" #input (keyup)="applyFilter($event)">
                    </form>
                    <button type="button" class="btn btn-gold m-1 btn-sm" (click)="createNewUser()"><i class="fa fa-plus"></i> Créer</button>
                    <button type="button" class="btn btn-gold m-1 btn-sm" (click)="downloadAllUsers()">
                        <i class="fa fa-download"></i>
                    </button>
                    <button type="button" class="btn btn-gold m-1 btn-sm" (click)="getUsers(true)">
                            <i *ngIf="refreshing" class="fa fa-refresh fa-spin"></i><i *ngIf="!refreshing" class="fa fa-refresh"></i>
                      </button>
                </ul>
            </div>
        </nav>
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="utilisateur">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Utilisateur </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectUser(row)"> {{row.firstName}} {{row.lastName}} </td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Rôle </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectUser(row)"> {{row.role}} </td>
            </ng-container>

            <ng-container matColumnDef="manager">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Manager </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectUser(row)"> {{row?.manager?.firstName}} {{row?.manager?.lastName}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Téléphone </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectUser(row)"> {{row.phoneNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="numberDaysLeave">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Balance(jours) </th>
                <td mat-cell *matCellDef="let row" (click)="onSelectUser(row)"> {{row.numberDaysLeave}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold"> Actions </th>
                <td mat-cell *matCellDef="let i = index">
                    <i class="fa fa-edit btn btn-gold" (click)="onEditUser(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                    <i class="fa fa-trash btn btn-gold ml-1" (click)="onDeleteUser(this.paginator.pageIndex * this.paginator.pageSize + i)"></i>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">Aucune donnée ne correspond à ce filtre: "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator class="bg-gray" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
</app-columnone>